
#navbar {
  background-color: #172b4d;
  margin-bottom: 10px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #f4f5f7;
  font-weight: bold;
  font-size: 18px;
}

.link {
  color: #172b4d;
  font-weight: bold;
  cursor:pointer;
}

.link-light {
  color: #5f8ddc !important;
  font-weight: bold;
  cursor:pointer;
  text-decoration: underline;
}

.blink {
  color : greenyellow;
  margin-right: 0.5rem;
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
  }
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink {
    to {
      visibility: hidden;
    }
  }

/* Card */

.card-header {
  background-color: #172b4d;
  color: #f4f5f7;
  font-weight: bold;
}

.card-header-warning {
  background-color: #b6751b;
  color: #f4f5f7;
  font-weight: bold;
}

.card-header-danger {
  background-color: #8a0000;
  color: #f4f5f7;
  font-weight: bold;
}

/* Buttons */
.btn-submit {
  background-color: #5e72e4;
  color: #f4f5f7;
}
.btn-tri {
  background-color: #fb6340;
  color: #f4f5f7;
}
.btn-delete {
  background-color: #f5365c;
  color: #f4f5f7;
}
.btn-send {
  background-color: #2dce89;
  color: #f4f5f7;
}
.btn-pri {
  background-color: #ced4da;
  color: #172b4d;
  width: min-content;
}
.btn-sec {
  background-color: #11cdef;
  color: #f4f5f7;
}
.btn-var {
  padding: 10px;
  background-color: #172b4d;
  color: #f4f5f7;
  border: 2px solid #ced4da;
  border-radius: 10px;
}

/* Text */
.text-disabled {
  color: gray;
}

/* Buttons (hover) */
.btn-submit:hover {
  background-color: #ced4da;
  color: #5e72e4;
}
.btn-tri:hover {
  background-color: #ced4da;
  color: #fb6340;
}
.btn-delete:hover {
  background-color: #f5365c;
}
.btn-send:hover {
  background-color: #ced4da;
  color: #2dce89;
}
.btn-pri:hover {
  background-color: #172b4d;
  color: #ced4da;
}
.btn-sec:hover {
  background-color: #ced4da;
  color: #11cdef;
}

.btn-link-bare{
  margin: 0;
  text-transform: none;
  color:#172b4d;
  border: none;
  background: none;
  font-weight: bold;
  text-decoration: underline;
}

.modal-x-large {
  min-width: 96%;
}

.modal-large {
  min-width: 75%;
}

.modal-half {
  min-width: 50%;
}

.modal-third {
  min-width: 33%;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  height: 42px;
  margin-top: 0px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropzoneContainer {
  border: thin solid black;
  padding-top: 25px;
  text-align: center;
}

.row-header {
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 10px;
}

.shield-row-header {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
}

.modal90 {
  width: 90%;
  max-width: none!important;
}

.gpstest-data-row{
  margin-bottom: 5px;
}

.gpstest-data-row-label{
  font-weight: bold;
}

.view-select-row{
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: thin solid #172b4d;
}

.view-select-dropdown{
  border-color: #242320;
  font-weight: 800;
  padding: 0.45rem 0.8rem;
  background-color: #faf9f7;
  line-height: 2.0;
  font-size: 1.3rem;
}

.analytic-more-info-row {
  margin-bottom: 5px;
}

.row-margin-bottom {
  margin-bottom: 20px;
}

.label-bold {
  font-weight: bold;
  display: block;
  margin-bottom: 0px;
}

.rma-item-label {
  font-weight: bold;
  display: block;
  margin-top: 5px;
  font-size: 14px;
}

.log-level-security {
  color:#517716;
}

.log-level-warning {
  font-weight: bold;
  color:#bad013;
}

.log-level-error {
  font-weight: bold;
  color:#78031b;
}

/*
  Menu styles
*/

.menu-sidebar-link{ 
  display: block;
  padding-left: 8px;
  padding-top: 2px;
  color: #757575;
  text-decoration: none;
}

.menu-link-header{
  display: block;
  padding-top: 12px;
  color: #757575;
  text-decoration: none;
  font-weight: bold;
}

.menu-divider{
  margin: 8px 0;
  height: 1px;
  background-color: #e0e0e0;
}

.menu-content{
  padding: 16px;
  background-color: #f2f2f2;
}


.menu-root{
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "sans-serif";
  font-weight: 300;
}


.menu-header{
  background-color: #f2f2f2;
  color: white;
  padding: 16px;
  font-size: 1.5em;
  width: 100%
}

.menu-content-holder {
  overflow-y:scroll;
  overflow-x: hidden;
}

.section-header-row-margin-top {
  margin-top: 16px;
}

.section-header {
  text-decoration: underline;
  font-size: 24px;
  font-weight: bolder;
}